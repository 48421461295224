import React from 'react'
import Branding from '../components/molecules/Branding/Branding'
import ExperienceDesc from '../components/molecules/ExperienceDesc/ExperienceDesc'
import LanguageWrapper from '../components/molecules/LanguageWrapper/LanguageWrapper'
import JoinUsForm from '../components/molecules/JoinUsForm'
import ContactUs from '../components/molecules/ContactUs'
import Testimonials from '../components/organisms/Testimonials'
import LanguageServices from '../components/organisms/LanguageServices/LanguageServices'
import { Helmet } from 'react-helmet'

const DigitalSolutions = () => {
    return (
        <div>
            {/* <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/services/digital-solutions" />
                <link rel="alternate" href="https://thecodingstudio.in/services/digital-solutions" hreflang="x-default" />
            </Helmet> */}
            <Helmet>
                <title>Digital Solutions Services - The Coding Studio | Transform Your Business</title>
                <meta
                    name="description"
                    content="The Coding Studio offers innovative digital solutions to enhance your business operations. From digital marketing to e-commerce strategies, our expert team is dedicated to driving growth and maximizing your online presence."
                />
                <meta
                    name="keywords"
                    content="Digital solutionsDigital marketing services,E-commerce development,Online branding,SEO optimization,Social media marketing,Content strategy,Business growth solutions"
                />
                {/* Open Graph / Facebook */}
                <meta property="og:title" content="Digital Solutions Services - The Coding Studio | Transform Your Business" />
                <meta
                    property="og:description"
                    content="The Coding Studio offers innovative digital solutions to enhance your business operations. From digital marketing to e-commerce strategies, our expert team is dedicated to driving growth and maximizing your online presence."
                />
                {/* <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}
                <meta property="og:url" content="https://thecodingstudio.in/services/digital-solutions" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital Solutions Services - The Coding Studio | Transform Your Business" />
                <meta
                    name="twitter:description"
                    content="The Coding Studio offers innovative digital solutions to enhance your business operations. From digital marketing to e-commerce strategies, our expert team is dedicated to driving growth and maximizing your online presence."
                />
                {/* <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}

                {/* Canonical and Alternate Links */}
                <link rel="canonical" href="https://thecodingstudio.in/services/digital-solutions" />
                <link rel="alternate" href="https://thecodingstudio.in/services/digital-solutions" hreflang="x-default" />
            </Helmet>
            <LanguageWrapper background={'bg-about-us-square bg-no-repeat'} />
            <ExperienceDesc title='Digital Solutions' />
            {/* <SatisfactionServices /> */}
            <Branding />
            <LanguageServices title='Digital Solutions' language='digital' filterKey='language' />
            <Testimonials />
            {/* <LanguageTeam role="Fullstack Developer" title="Fullstack" /> */}
            <div className='mx-auto lg:mx-[-100px] mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default DigitalSolutions
