import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'
import Testimonials from '../components/organisms/Testimonials'
import Branding from '../components/molecules/Branding/Branding'
import LanguageWrapper from '../components/molecules/LanguageWrapper/LanguageWrapper'
import ExperienceDesc from '../components/molecules/ExperienceDesc/ExperienceDesc'
import LanguageServices from '../components/organisms/LanguageServices/LanguageServices'
import { Helmet } from 'react-helmet'

const FullstackPage = () => {
    return (
        <div>
            {/* <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/services/fullstack" />
                <link rel="alternate" href="https://thecodingstudio.in/services/fullstack" hreflang="x-default" />
            </Helmet> */}
            <Helmet>
                <title>Full Stack Development Services - The Coding Studio | Complete Web Solutions</title>
                <meta
                    name="description"
                    content="The Coding Studio provides comprehensive full stack development services, combining frontend and backend expertise. Our skilled developers create seamless, efficient web applications tailored to your business needs."
                />
                <meta
                    name="keywords"
                    content="Full stack development,Web application development,Frontend and backend integration,JavaScript frameworks,Database management,API development,Scalable web solutions,Cross-platform applications"
                />
                {/* Open Graph / Facebook */}
                <meta property="og:title" content="Full Stack Development Services - The Coding Studio | Complete Web Solutions" />
                <meta
                    property="og:description"
                    content="The Coding Studio provides comprehensive full stack development services, combining frontend and backend expertise. Our skilled developers create seamless, efficient web applications tailored to your business needs."
                />
                {/* <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}
                <meta property="og:url" content="https://thecodingstudio.in/services/fullstack" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Full Stack Development Services - The Coding Studio | Complete Web Solutions" />
                <meta
                    name="twitter:description"
                    content="The Coding Studio provides comprehensive full stack development services, combining frontend and backend expertise. Our skilled developers create seamless, efficient web applications tailored to your business needs."
                />
                {/* <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}

                {/* Canonical and Alternate Links */}
                <link rel="canonical" href="https://thecodingstudio.in/services/fullstack" />
                <link rel="alternate" href="https://thecodingstudio.in/services/fullstack" hreflang="x-default" />
            </Helmet>
            <LanguageWrapper background={'bg-fullstack-development'} />
            <ExperienceDesc title='fullstack' />
            {/* <SatisfactionServices /> */}
            <Branding />
            <LanguageServices title='Fullstack' language='fullstack' filterKey='language' />
            <Testimonials />
            {/* <LanguageTeam role="Fullstack Developer" title="Fullstack" /> */}
            <div className='mx-auto lg:mx-[-100px] mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default FullstackPage