import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'
import Testimonials from '../components/organisms/Testimonials'
import Branding from '../components/molecules/Branding/Branding'
import LanguageWrapper from '../components/molecules/LanguageWrapper/LanguageWrapper'
import LanguageServices from '../components/organisms/LanguageServices/LanguageServices'
import SatisfactionServices from '../components/molecules/SatisfactionServices/SatisfactionServices'
import ExperienceDesc from '../components/molecules/ExperienceDesc/ExperienceDesc'
import { Helmet } from 'react-helmet'

const FrontendPage = () => {
    return (
        <div>
            {/* <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/services/frontend" />
                <link rel="alternate" href="https://thecodingstudio.in/services/frontend" hreflang="x-default" />
            </Helmet> */}
            <Helmet>
                <title>Frontend Development Services - The Coding Studio | Engaging User Experiences</title>
                <meta
                    name="description"
                    content="At The Coding Studio, we specialize in frontend development to create visually stunning and interactive web applications. Our team utilizes the latest technologies to ensure a seamless user experience across all devices."
                />
                <meta
                    name="keywords"
                    content="Frontend development,Web application design,HTML/CSS development,JavaScript frameworks,Responsive design,User interface (UI) design,Interactive web solutions,Cross-browser compatibility"
                />
                {/* Open Graph / Facebook */}
                <meta property="og:title" content="Frontend Development Services - The Coding Studio | Engaging User Experiences" />
                <meta
                    property="og:description"
                    content="At The Coding Studio, we specialize in frontend development to create visually stunning and interactive web applications. Our team utilizes the latest technologies to ensure a seamless user experience across all devices."
                />
                {/* <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}
                <meta property="og:url" content="https://thecodingstudio.in/services/frontend" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Frontend Development Services - The Coding Studio | Engaging User Experiences" />
                <meta
                    name="twitter:description"
                    content="At The Coding Studio, we specialize in frontend development to create visually stunning and interactive web applications. Our team utilizes the latest technologies to ensure a seamless user experience across all devices."
                />
                {/* <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}

                {/* Canonical and Alternate Links */}
                <link rel="canonical" href="https://thecodingstudio.in/services/frontend" />
                <link rel="alternate" href="https://thecodingstudio.in/services/frontend" hreflang="x-default" />
            </Helmet>
            <LanguageWrapper background={'bg-frontend-development'} />
            <ExperienceDesc title='frontend' />
            {/* <SatisfactionServices /> */}
            <Branding />
            <LanguageServices title='Frontend' language='frontend' filterKey='language' />
            <Testimonials />
            {/* <LanguageTeam role="Frontend Developer" title="Frontend" /> */}
            <div className='mx-auto lg:mx-[-100px] mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default FrontendPage