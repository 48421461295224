import React, { useRef } from 'react'
import UnderLineText from '../components/atoms/UnderlineText'
import ContactUs from '../components/molecules/ContactUs'
import AboutUs from '../components/organisms/AboutUs'
import CoreValues from '../components/organisms/CoreValues'
import OurServices from '../components/organisms/OurServices'
import Technologies from '../components/organisms/Technologies'
import Testimonials from '../components/organisms/Testimonials'
import commonConstants from '../utils/constants'
import CeoMessage from '../components/organisms/CeoMessage/CeoMessage';
import { motion, useInView } from 'framer-motion'
import { Helmet } from 'react-helmet'

const HomePage = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 0.5 })

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.01,
      }
    }
  }

  // const text = 'Transform your groundbreaking ideas into market-ready products'

  return (
    <section className='text-black px-2 md:px-5 pb-6 bg-home-bg-decoration bg-no-repeat bg-relative [background-position-x:87%] [background-position-y:7.6%] lg:px-[100px] h-[100%]'>
      {/* Canonical Tag and SEO */}
      <Helmet>
        <title>The Coding Studio - Premier Web & Mobile App Development</title>
        <meta
          name="description"
          content="The Coding Studio specializes in bespoke web and mobile app development, offering innovative solutions tailored to meet your business needs. Elevate your digital presence with our expert services."
        />
        <meta
          name="keywords"
          content="Web development, Mobile app development, Custom software solutions, E-commerce development, UI/UX design, Digital marketing, IT consulting"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="The Coding Studio - Premier Web & Mobile App Development" />
        <meta
          property="og:description"
          content="The Coding Studio specializes in bespoke web and mobile app development, offering innovative solutions tailored to meet your business needs. Elevate your digital presence with our expert services."
        />
        <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" />
        <meta property="og:url" content="https://thecodingstudio.in/" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="The Coding Studio - Premier Web & Mobile App Development" />
        <meta
          name="twitter:description"
          content="The Coding Studio specializes in bespoke web and mobile app development, offering innovative solutions tailored to meet your business needs. Elevate your digital presence with our expert services."
        />
        <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" />

        {/* Canonical and Alternate Links */}
        <link rel="canonical" href="https://thecodingstudio.in/" />
        <link rel="alternate" href="https://thecodingstudio.in/" hreflang="x-default" />
      </Helmet>
      <div className='flex flex-col lg:flex-row justify-between gap-3 items-center' >
        <div data-aos="fade-right" className='flex flex-col gap-5 max-w-[900px] mt-[5%]'>
          <span className='text-[32px] md:text-[49px] leading-[40px] md:leading-[65px] font-[700]'>
            Empowering <UnderLineText isThickUnderline={true} text={'Startups'} /> to Innovate, Scale, and Thrive
          </span>
          <span className=' max-w-[600px] text-[20px] leading-[24px] font-[400]'>
            Transform your groundbreaking ideas into market-ready products
          </span>
          <span className='mt-[30px] text-[20px] leading-[24px] font-[500]'>
            Our Expertise in:
          </span>
          <div className='flex gap-2 flex-col flex-wrap max-w-[600px]' >
            {
              commonConstants.expertise.map((tech, idx) => {
                return (
                  <div className='relative' key={idx}>
                    {/* <div className='themed-border rounded-[50%] animate-spin-slow absolute inset-0'></div> */}
                    {/* <div className="flex items-center justify-center w-[70px] h-[70px] rounded-[50%] relative" key={tech.key}> */}
                    {/* <img src={tech.icon} alt={tech.name} /> */}
                    {<motion.div ref={ref} initial={isInView ? 'visible' : 'hidden'} animate='visible' transition={{ staggerChildren: 0.15 }} data-aos="zoom-in-right" className='relative text-[#db805fa1] text-[18px] md:text-[24px] font-[500] [text-shadow:_1px_1px_#FF5D22]'>
                      <motion.span variants={textVariants}>
                        {tech.name}
                      </motion.span>
                    </motion.div>}
                    {/* </div> */}
                  </div>
                )
              })
            }
          </div>
        </div>
        <img data-aos="flip-right" className='w-auto md:w-[50%] rounded-[33px] mt-[10px]' src='/images/teamwork_1jpg.jpg' alt='intro' />
      </div>
      <AboutUs />
      <Testimonials />
      <OurServices />
      <Technologies />
      <CeoMessage />
      <CoreValues />
      <ContactUs />
    </section>
  )
}

export default HomePage