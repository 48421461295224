import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import Advantages from '../components/organisms/Advantages'
import Intro from '../components/organisms/Intro'
import OurGallary from '../components/organisms/OurGallary'
import OurHead from '../components/organisms/OurHead'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'

const AboutUsPage = () => {
    const scrollToDownFunctionById = () => {
        var elem = document.getElementById("ele");
        elem.scrollIntoView({
            behavior: 'smooth'
        });
    };
    return (
        <div className='text-black pb-6'>
            <Helmet>
                <title>About Us - The Coding Studio | Innovators in Web & Mobile Development</title>
                <meta
                    name="description"
                    content="Discover The Coding Studio, where passion meets expertise. Our dedicated team specializes in web and mobile app development, providing tailored solutions to help businesses thrive in the digital age."
                />
                <meta
                    name="keywords"
                    content="About The Coding Studio,Web development experts,Mobile app development,Custom software solutions,Digital transformation,Innovative technology solutions,Tech-driven team"
                />

                {/* Open Graph / Facebook */}
                <meta property="og:title" content="About Us - The Coding Studio | Innovators in Web & Mobile Development" />
                <meta
                    property="og:description"
                    content="Discover The Coding Studio, where passion meets expertise. Our dedicated team specializes in web and mobile app development, providing tailored solutions to help businesses thrive in the digital age."
                />
                {/* <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}
                <meta property="og:url" content="https://thecodingstudio.in/about-us" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Us - The Coding Studio | Innovators in Web & Mobile Development" />
                <meta
                    name="twitter:description"
                    content="Discover The Coding Studio, where passion meets expertise. Our dedicated team specializes in web and mobile app development, providing tailored solutions to help businesses thrive in the digital age."
                />
                {/* <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}

                {/* Canonical and Alternate Links */}
                <link rel="canonical" href="https://thecodingstudio.in/about-us" />
                <link rel="alternate" href="https://thecodingstudio.in/about-us" hreflang="x-default" />
            </Helmet>
            <div className='flex flex-col items-center px-[35px] md:px-[100px] bg-about-us-square bg-no-repeat bg-contain pb-[110px] pt-[60px] md:pt-[120px] w-[100%] relative z-[1]'>
                <motion.p initial={{ opacity: 0 }}
                    animate={{ opacity: [0.5, 1, 0.5] }}
                    transition={{ duration: 2, repeat: Infinity }} data-aos="zoom-in" className='absolute text-[9vw] md:text-[11vw] font-bold tracking-[15px] top-[40%] left-[16%] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000000_-130%,_rgba(0,_0,_0,_0)_79%)]'>
                    ABOUT US
                </motion.p>
                <div className='flex justify-center gap-8'>
                    <img data-aos="fade-right" className='w-[15%] mb-[-15%]' src='/svg/about-img-1.svg' alt='' />
                    <img data-aos="zoom-in" className='ml-[-7%] w-[65%]' src='/svg/about-ideas.svg' alt='ideas' />
                    <img data-aos="fade-left" className='w-[15%] mt-[-15%]' src='/svg/about-img-2.svg' alt='about' />
                </div>
                <div className='relative mt-[15%]'>
                    <div
                        data-aos="fade-down"
                        className='animate-spin-slow outline-3 outline-dashed outline-offset-[-5px] md:outline-offset-[6px] rounded-[50%] flex items-center justify-center cursor-pointer absolute inset-0 w-[70px] h-[72px] left-[-48px] md:left-auto'
                    >
                        <div className='w-[55px] md:w-auto bg-black rounded-[50%] p-[22px]' onClick={scrollToDownFunctionById}>
                            <motion.img animate={{ y: [0, 10, 0] }} transition={{ repeat: Infinity, duration: 1 }} src='/svg/arrow-down-black.svg' alt="Arrow Down" />
                        </div>
                    </div>
                </div>
                {/* <div data-aos="fade-up-left" className='relative' >
                    <div className='themed-border rounded-[50%] animate-spin-slow absolute inset-0'></div>
                    <div className="flex items-center justify-center w-[100px] h-[100px] rounded-[50%] relative" key={tech.key}>
                        <img src={tech.icon} alt={tech.name} />
                    </div>
                </div> */}
                {/* <img data-aos="fade-down" className='w-[5%] h-[5%] mt-[15%] cursor-pointer' src='/svg/arrow-down-black.svg' /> */}

            </div>
            <Intro />
            <Advantages />
            {/* <OurHead /> */}
            {/* <OurTeam /> */}
            {/* <OurAlumanis /> */}
            <OurGallary />
            <ContactUs />
        </div>
    )
}

export default AboutUsPage