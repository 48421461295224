import React from 'react'
import JoinUsForm from '../components/molecules/JoinUsForm'
import GalleryTabs from '../components/organisms/GalleryTabs/GalleryTabs'
import { Helmet } from 'react-helmet'

const GalleryPage = () => {
    return (
        <div className='text-black relative px-2 lg:px-[100px]'>
            {/* <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/gallery" />
                <link rel="alternate" href="https://thecodingstudio.in/gallery" hreflang="x-default" />
            </Helmet> */}
            <Helmet>
                <title>Gallery - The Coding Studio | Showcase of Our Work</title>
                <meta
                    name="description"
                    content="Explore the Gallery of The Coding Studio to view our diverse portfolio. Discover our web and mobile development projects that highlight our expertise in creating innovative and user-friendly digital solutions."
                />
                <meta
                    name="keywords"
                    content="Custom IT solutionsWeb and app development,Digital transformation,UI/UX design,E-commerce solutions,Cloud services,Data migration and recovery"
                />
                {/* Open Graph / Facebook */}
                <meta property="og:title" content="Gallery - The Coding Studio | Showcase of Our Work" />
                <meta
                    property="og:description"
                    content="Explore the Gallery of The Coding Studio to view our diverse portfolio. Discover our web and mobile development projects that highlight our expertise in creating innovative and user-friendly digital solutions."
                />
                {/* <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}
                <meta property="og:url" content="https://thecodingstudio.in/gallery" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Gallery - The Coding Studio | Showcase of Our Work" />
                <meta
                    name="twitter:description"
                    content="Explore the Gallery of The Coding Studio to view our diverse portfolio. Discover our web and mobile development projects that highlight our expertise in creating innovative and user-friendly digital solutions."
                />
                {/* <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}

                {/* Canonical and Alternate Links */}
                <link rel="canonical" href="https://thecodingstudio.in/gallery" />
                <link rel="alternate" href="https://thecodingstudio.in/gallery" hreflang="x-default" />
            </Helmet>
            <div className=' relative flex flex-col items-center bg-[center] [background-size:100%] pt-[14%] bg-gallery bg-no-repeat w-[100%] relative z-[1]'>
                <div className='relative'>
                    <div className='absolute w-[20%] md:w-auto top-[-15%] md:top-[-62%] left-[7%]'>
                        <img data-aos="fade-right" src='/svg/left-hand-wave.svg' className=' animate-swing' alt='hand-wave' />
                    </div>
                    <div className='flex flex-col items-center'>
                        <div className='text-center text-[48px] md:text-[68px] font-[700] leading-[66px] md:leading-[100px] flex flex-col items-center justify-center '>
                            <div data-aos="fade-up">We Work Hard, We Have Fun</div>
                            <div data-aos="fade-up" className='[background:_#FF5D22] text-white px-[30px]'>We Make a Difference!</div>
                        </div>
                        <p data-aos="zoom-in" className='text-[13vw] font-[700] tracking-[15px] z-0 text-transparent [background-clip:text_!important] [background:_linear-gradient(to_bottom,_#000_-27%,_#fff_79%)] uppercase font-sans'>
                            Gallery
                        </p></div>
                    <div className='realtive'>
                        <img data-aos="fade-right" src='/svg/space-jet.svg' className='absolute w-[25%] lg:w-auto top-[15%] right-[-5%] lg:top-[-39%] lg:right-[-20%]' alt='space-jet' />
                    </div>


                </div>
            </div>
            <GalleryTabs />
            <div className='mt-[100px]'>
                <JoinUsForm />
            </div>
        </div>
    )
}

export default GalleryPage
