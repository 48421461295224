import React from 'react'
import ContactUs from '../components/molecules/ContactUs'
import JoinUsForm from '../components/molecules/JoinUsForm'
import Testimonials from '../components/organisms/Testimonials'
import Branding from '../components/molecules/Branding/Branding'
import LanguageWrapper from '../components/molecules/LanguageWrapper/LanguageWrapper'
import LanguageServices from '../components/organisms/LanguageServices/LanguageServices'
import SatisfactionServices from '../components/molecules/SatisfactionServices/SatisfactionServices'
import ExperienceDesc from '../components/molecules/ExperienceDesc/ExperienceDesc'
import { Helmet } from 'react-helmet'

const BackendPage = () => {
    return (
        <div>
            {/* <Helmet>
                <link rel="canonical" href="https://thecodingstudio.in/services/backend" />
                <link rel="alternate" href="https://thecodingstudio.in/services/backend" hreflang="x-default" />
            </Helmet> */}
            <Helmet>
                <title>Backend Development Services - The Coding Studio | Robust and Scalable Solutions</title>
                <meta
                    name="description"
                    content="The Coding Studio offers expert backend development services to ensure your web applications run smoothly and efficiently. Our team specializes in server-side technologies, database management, and API integration for scalable solutions."
                />
                <meta
                    name="keywords"
                    content="Backend development,Server-side development,Database management,API integration,Scalable web solutions,Web application architecture,Node.js development,PHP and Python development"
                />
                {/* Open Graph / Facebook */}
                <meta property="og:title" content="Backend Development Services - The Coding Studio | Robust and Scalable Solutions" />
                <meta
                    property="og:description"
                    content="The Coding Studio offers expert backend development services to ensure your web applications run smoothly and efficiently. Our team specializes in server-side technologies, database management, and API integration for scalable solutions."
                />
                {/* <meta property="og:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}
                <meta property="og:url" content="https://thecodingstudio.in/services/backend" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Backend Development Services - The Coding Studio | Robust and Scalable Solutions" />
                <meta
                    name="twitter:description"
                    content="The Coding Studio offers expert backend development services to ensure your web applications run smoothly and efficiently. Our team specializes in server-side technologies, database management, and API integration for scalable solutions."
                />
                {/* <meta name="twitter:image" content="https://thecodingstudio.in/images/homepage-preview.jpg" /> */}

                {/* Canonical and Alternate Links */}
                <link rel="canonical" href="https://thecodingstudio.in/services/backend" />
                <link rel="alternate" href="https://thecodingstudio.in/services/backend" hreflang="x-default" />
            </Helmet>
            <LanguageWrapper background={'bg-backend-development'} />
            <ExperienceDesc title='backend' />
            {/* <SatisfactionServices /> */}
            <Branding />
            <LanguageServices title='Backend' language='backend' filterKey='language' />
            <Testimonials />
            {/* <LanguageTeam role="Backend Developer" title="Backend" /> */}
            <div className='mx-auto lg:mx-[-100px] mt-[100px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default BackendPage